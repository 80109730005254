<template>
  <div class="intro">
    <div class="order_nav d_f ali_c">
      <img src="./img/order_back.png" alt="" class="cur_p" @click="exit()">
      <van-dropdown-menu active-color="#1DDF50" class="flex_1">
        <van-dropdown-item v-model="chooseOrderType" :options="orderType"  @change="changeType"/>
      </van-dropdown-menu>
    </div>
    <div class="content">
      <!--景点门票-->
      <template v-if="chooseOrderType === 0">
        <!--tab-->
        <div class="ol_tab d_f ali_c j_s">
          <div class="item_out flex_1 cur_p"  @click="switchState(item,idx)" v-for="(item,idx) in tabs" :key="idx" :class="idx === acIdx ? 'active' : ''">
          <span class="title">
            <span>{{item.name}}</span>
            <div class="cut"></div>
          </span>
          </div>
        </div>
        <!--orderList-->
        <div class="ol_list">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              :immediate-check="false"
              v-model="isLoading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div class="ol_card" v-for="(item,index) in orderlist" :key="index" @click.stop="goToDetail(item)">
                <div class="head d_f ali_c">
                  <div class="d_f ali_c flex_1 van-overflow-hidden">
                    <img src="./img/ol_title.png" alt="">
                    <span class="title text_over flex_1">{{item.orderDetails.length>0 ? item.orderDetails[0].commoditytName:'--'}}</span>
                  </div>
                  <div class="status_text t_r" :class="getStatusText(item).color ? getStatusText(item).color : ''">{{getStatusText(item).name}}</div>
                </div>
                <div class="ol_content">
                  <div class="ol_detail d_f">
                    <div class="logo">
                      <img v-if="item.orderDetails.length" :src="item.orderDetails[0].commodityCover" alt="">
                      <img v-else src="./img/ol_title.png" alt="">
                    </div>
                    <div class="flex_1">
                      
                      <div class="top ali_c d_f j_s">
                        <span>共{{ item.orderDetails.length }}张</span>
                        <span  v-if="item.orderDetails.length>0">{{item.orderDetails[0].beginDate+'至'+item.orderDetails[0].endDate+'有效'}}</span>
                      </div>
                      <div class="center">
                        <span>总价：</span>
                        <span class="price" v-if="item.orderDetails.length>0">￥{{ item.orderDetails[0].sellPrice ? ((item.orderDetails[0].sellPrice*item.orderDetails.length)/100).toFixed(2) : 0}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="ol_btn t_r">
                    <div class="cur_p ol_btn_out" @click.stop="otherState(item)" v-if="acIdx === 3">立即评价</div>
                    <div class="cur_p ol_btn_out" @click.stop="cancelOrder(item)" v-if="getStatusText(item).num == 0">取消</div>
                    <div class="cur_p ol_btn_out" @click.stop="gotoTicketsDetail(item)" v-if="getStatusText(item).num < 3">再次预订</div>
                    <div class="cur_p ol_btn_out" @click.stop="refundPay(item)" v-if="item.btnRefund">申请退款</div>
                    <div class="cur_p ol_btn_out green" @click.stop="gotoPay(item)" v-if="getStatusText(item).num == 0">去支付</div>
                    <div class="cur_p ol_btn_out" @click.stop="goToRefund(item.order.orderNumber)" v-if="getStatusText(item).num>6">退款进度</div>
                    <div class="cur_p ol_btn_out" @click.stop="goToDetail(item)" v-if="getStatusText(item).num == 11 || getStatusText(item).num == 10">查看详情</div>
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>

      </template>
      <!--酒店订单-->
      <template v-if="chooseOrderType === 1">
        <hotelList></hotelList>
      </template>
      <!--酒店订单-->
      <template v-if="chooseOrderType === 3">
        <comboList></comboList>
      </template>
    </div>


    <!--二级评论框-->
    <van-overlay :show="showInp" @click="showInp = false" >
      <div class="wrapperInp" >
        <div class="wrapperInpBloack" ref="textareaRef" @click.stop>
          <div class="wrapperInp_head">
            <span>我要评论</span>
            <van-icon name="arrow-down" @click="showInp = false" />
          </div>
          <div class="wrapperInp_text">
            <div class="d_f ali_c score_out">
              <span>整体评分</span>
              <van-rate
                v-model="stateInfo.data.starScore"
                :size="16"
                color="#FF577B"
                void-icon="star-o"
                allow-half
                void-color="#FF577B"
              />
            </div>
            <!--<textarea data-index="1" ref='focusTextarea' rows="3" cols="20" v-model="content" placeholder="请输入留言">-->

            <!--</textarea>-->
            <van-field
              refs='commentCearch'
              v-model="stateInfo.data.content"
              rows="1"
              :autofocus="true"
              ref="commentCearch"
              type="textarea"
              maxlength="140"
              placeholder="我要说点什么"
              show-word-limit
            />
          </div>
          <div class="wrapperInpBtn" @click="addOther()">
            发表
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script src="./orderList.js">
</script>

<style scoped lang="scss">
  .score_out{
    span{
      margin-right: 24px;
    }
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #141212;
  }
  .wrapperInp_text{
    border-top: 1px solid #DEDEDE;
  }
  .intro .content{
    height: calc(100% - 55px);
    overflow: hidden;
  }
  /*门票下拉*/
  .olc_choose_pop_out{
    background-color: white;
    border-radius: 5px;
    top: 20px;
    right: 0;
    border: 1px solid #e4e4e4;
    width: 65px;
    z-index: 999;
    border-bottom: none;
    text-align: center;
    .olc_choose_pop_item{
      line-height: 35px;
      border-bottom: 1px solid #e4e4e4;
    }
  }
  /*弹窗*/
  .wrapperInp{
    display: flex;
    /*align-items: flex-end;*/
    justify-content: center;
    position: relative;
    overflow: auto;
    height: 100%;
  }
  .wrapperInpBloack{
    width: 100%;
    height: 245px;
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
    background-color: white;
  }
  .wrapperInp_head{
    width: 100%;
    padding: 11px 20px 15px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: bold;

  }
  .wrapperInp_text{
    width: 100%;
    padding: 18px 20px;
    box-sizing: border-box;
  }
  .wrapperInp_text textarea{
    border: none;
    width: 100%;
    height: 80px;
    padding: 10px 8px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #efefef!important;
  }
  .van-field{
    background-color: #efefef!important;
  }
  .wrapperInpBtn{
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 10px 20px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    box-sizing: border-box;
    background:linear-gradient(90deg,#92E460,#3BD367);
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .ol_list{
    .ol_card{
      .ol_icon{
        width: 81px;
        right: 4px;
        top: 43px;
        img{
          width: 100%;
        }
      }
      .ol_content{
        .ol_btn_out{
          margin-top: 15px;
          &.green{
            border:1px solid rgba(26,204,112,1);
            background-color: rgba(26,204,112,1);
            color:white;
          }
          border:1px solid rgba(20,18,18,1);
          color:rgba(20,18,18,1);
          font-size:14px;
          padding: 0 11px;

          font-weight:400;
          border-radius:2px;
          text-align: center;
          height: 24px;
          line-height: 24px;
          display: inline-block;
          margin-left: 11px;
        }
        .ol_detail{
          .center{
            .price{
              color: #FF3E6E;
            }
            font-size:12px;

            font-weight:400;
            color:rgba(87,87,87,1);
          }
          .top{
            .ml_28{
              margin-left: 28px;
            }
            margin-bottom: 11px;
            font-size:12px;

            font-weight:400;
            color:rgba(87,87,87,1);
          }
          .logo{
            width:60px;
            height:60px;
            border-radius:5px;
            overflow: hidden;
            margin-right: 15px;
            img{
              height: 100%;
            }
          }
        }
        padding: 15px 13px 15px 9px;
      }
      .head{
        border-bottom: 1px solid rgba(211, 211, 211, 0.51);
        .status_text{
          font-size:14px;

          font-weight:bold;
          &.green{  /*代付款*/
            color:rgba(26,204,112,1);
          }
          &.dark{  /*出票中，取票失败，取消*/
            color:#141212;
          }
          &.orange{  /*待使用*/
            color:#FF9A0A;
          }
          &.pink{  /*已使用*/
            color:#FF3E6E;
          }
        }
        .title{
          font-size:15px;

          font-weight:500;
          color:rgba(51,51,51,1);
        }
        img{
          margin-right: 6px;
          width: 23px;
        }
        height: 38px;
        padding: 0 13px 0 6px;
      }
      background:rgba(255,255,255,1);
      border-radius:5px;
      margin-bottom: 15px;
      position: relative;
      overflow: hidden;
    }
    background:rgba(247,247,247,1);
    padding: 14px 20px;
    overflow: auto;
    height: calc(100% - 100px);
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }
  .ol_tab{
    background-color: white;
    .item_out{
      .title{
        display: inline-block;
        font-size:15px;

        font-weight:500;
        color:rgba(87,87,87,1);
      }
      .cut{
        background-color: rgba(0,0,0,0);
        margin-top: 6px;
        height: 4px;
      }
      &.active{
        .title{
          font-weight:bold;
          color:rgba(20,18,18,1);
        }
        .cut{
          background:linear-gradient(90deg,rgba(146,228,96,1),rgba(59,211,103,1));
        }
      }
    }
    height: 48px;
    padding: 0 15px;
  }
  .ol_choose{
    background-color: white;
    img{
      width: 9px;
    }
    .olc_choose{
      font-size:12px;

      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-right: 7px;
    }
    .title{
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
    }
    height: 42px;
    padding: 0 24px 0 21px;
    border-bottom: 4px solid #F7F7F7;
  }
  .intro .content{
    padding: 0;
  }
  .intro{
    height: 100%;
    background-color: #f7f7f7;
  }
</style>
