<template>
  <div class="ol_out" :class="orderArr.length ? '':'bg_white'">
    <nav class="ol_tab ali_c j_s d_f">
      <div class="ol_tab_item" v-for="(item,idx) in navArr" :class="idx === acNav ? 'ac' : ''" @click="changeState(item,idx)">
        <span class="p_r d_i">{{item.name}}</span>
      </div>
    </nav>
    <section class="ol_card_out">
      <div v-if="orderArr.length || refreshing || isLoading">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            :immediate-check="false"
            v-model="isLoading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoadBottom"
          >
            <div class="olc_item_out" v-for="item in orderArr">
              <div class="head d_f ali_c">
                <img src="../img/order_icon.png" alt="" class="icon">
                <span>智游乐山酒店专营</span>
                <template v-if="item.order.orderStatus == 2 && item.order.closeType == 2">
                  <span class="flex_1 t_r">已取消</span>
                </template>
                <template v-else>
                  <template v-if="item.orderDetails[0].refundStatus === null || item.orderDetails[0].refundStatus === 0">
                    <span class="flex_1 t_r green" v-if="(item.order.orderStatus == 1 || item.order.orderStatus == 0) && (new Date($renderTime(item.orderDetails[0].creatorTime)).getTime() + 15 * 60 * 1000 > new Date().getTime())">待付款，剩余<van-count-down @finish="reloadList" class="d_i" :time="new Date($renderTime(item.orderDetails[0].creatorTime)).getTime() + 15 * 60 * 1000 - new Date().getTime()"/></span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 2">已取消</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 3">支付失败</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 4">待确认</span>
                    <span class="flex_1 t_r green" v-if="item.order.orderStatus == 5">预订成功</span>
                    <span class="flex_1 t_r" v-if="item.order.orderStatus == 7">已消费</span>
                  </template>
                  <template v-else>
                    <span class="flex_1 t_r green" v-if="item.orderDetails[0].orderRefundStatus == 1">退款审核中...</span>
                    <span class="flex_1 t_r green" v-if="item.orderDetails[0].orderRefundStatus == 2">退票中...</span>
                    <span class="flex_1 t_r green" v-if="item.orderDetails[0].orderRefundStatus == 3">退票失败</span>
                    <span class="flex_1 t_r green" v-if="item.orderDetails[0].orderRefundStatus == 4">退款中</span>
                    <span class="flex_1 t_r green" v-if="item.orderDetails[0].orderRefundStatus == 5">退款失败</span>
                    <span class="flex_1 t_r green" v-if="item.orderDetails[0].orderRefundStatus == 6">退款成功</span>
                    <span class="flex_1 t_r green" v-if="item.orderDetails[0].orderRefundStatus == 7">财务审核中</span>
                    <span class="flex_1 t_r green" v-if="item.orderDetails[0].orderRefundStatus == 8">退款失败</span>
                    <!--<span class="flex_1 t_r green" v-if="item.orderDetails[0].orderRefundStatus == 8">预订成功</span>-->
                  </template>
                </template>
                <!--<span class="flex_1 t_r" v-if="item.order.orderStatus == 11">已取消（用户）</span>-->
                <!--<span class="flex_1 t_r" v-if="item.order.orderStatus == 3">已取消（商家）</span>  &lt;!&ndash;?????&ndash;&gt;-->
              </div>
              <div class="olc_content d_f" @click="goToDetail(item.order.orderNumber)">
                <van-image
                  fit="cover"
                  :src="item.orderDetails[0].commodityCover"
                />
                <div class="olcc_right flex_1">
                  <div class="name">{{item.orderDetails[0].hotelName}}</div>
                  <div class="sub">
                    <span>{{item.orderDetails[0].commoditytName+'-'+item.orderDetails[0].bedType}} |</span>
                    <span v-if="item.orderDetails[0].breakfast>0">含早餐 |</span>
                    <span v-if="item.orderDetails[0].window>0">有窗 |</span>
                    <span>{{item.orderDetails[0].capacity}}人入住</span>
                  </div>
                  <div class="sub">入离日期 {{item.orderDetails[0].beginDate.split('-')[1]+'-'+item.orderDetails[0].beginDate.split('-')[2] + ' 至 ' + item.orderDetails[0].endDate.split('-')[1]+'-'+item.orderDetails[0].endDate.split('-')[2]}}</div>
                  <div class="price d_f ali_b">
                    <span>共计{{item.order.totalPurchaseNumber}}间</span>
                    <span v-show="item.order.totalDiscountAmount == 0">合计</span>
                    <span v-show="item.order.totalDiscountAmount == 0">￥{{item.order.totalSellPrice ? item.order.totalSellPrice/100 : 0}}</span>
                  </div>
                </div>
              </div>
              <div class="jfje" v-show="item.order.totalDiscountAmount > 0">
                <span>总价 ￥{{item.order.totalListedPrice / 100}}</span>
                <span>积分优惠 ￥{{item.order.totalDiscountAmount / 100}}</span>
                <span>实付 ￥{{item.order.totalSellPrice / 100}}</span>
              </div>
              <div class="olc_btn_out t_r">
                <!--<div class="cur_p d_i t_a olc_btn refund_btn" @click.stop="refundPay(item)">申请退款</div>-->

                <div class="cur_p d_i t_a olc_btn" @click.stop="cancelOrder(item)" v-if="item.order.orderStatus == 1 || item.order.orderStatus == 0">取消订单</div>
                <div class="cur_p d_i t_a olc_btn ac" @click.stop="gotoPay(item)" v-if="item.order.orderStatus == 1 || item.order.orderStatus == 0">立即付款</div>
                <div class="cur_p d_i t_a olc_btn" @click.stop="deleteOrder(item)" v-if="item.orderDetails[0].orderRefundStatus == 6 || item.order.orderStatus == 2">删除订单</div>
                <div class="cur_p d_i t_a olc_btn refund_btn" @click.stop="refundPay(item)"  v-if="(item.order.orderStatus == 5 && (item.orderDetails[0].refundStatus === null|| item.orderDetails[0].refundStatus === 0))">申请退款</div>
                <div class="cur_p d_i t_a olc_btn" v-if="item.orderDetails[0].orderRefundStatus == 1 || item.order.orderStatus == 4"><a :href="'tel:'+ item.orderDetails[0].telephone">联系酒店</a></div>
                <div class="cur_p d_i t_a olc_btn" v-if="item.orderDetails[0].orderRefundStatus == 5"><a href="tel:4008196333">联系客服</a></div>
                <div class="cur_p d_i t_a olc_btn" @click.stop="goToDetail(item.order.orderNumber)" v-if="item.order.orderStatus == 6">查看详情</div>
                <!--<div class="cur_p d_i t_a olc_btn" @click.stop="hotelDetail(item)" v-if="item.order.orderStatus == 6 || item.order.orderStatus == 4">查看详情</div>-->
                <!--<div class="cur_p d_i t_a olc_btn" @click.stop="goToDetail(item.order.orderNumber)">查看详情</div>-->
                <div class="cur_p d_i t_a olc_btn" @click.stop="recallOrder(item)" v-if="item.orderDetails[0].orderRefundStatus == 1">撤销申请</div>
                <div class="cur_p d_i t_a olc_btn" @click.stop="goComment(item)" v-if="(item.orderDetails[0].refundStatus === null || item.orderDetails[0].refundStatus === 0)&& item.order.orderStatus == 7 && item.orderDetails[0].isPreview == 0">立即评价</div>
                <div class="cur_p d_i t_a olc_btn" @click.stop="$router.push('/hotelInner?sellerId='+item.orderDetails[0].sellerId)"  v-if="((item.orderDetails[0].refundStatus === null || item.orderDetails[0].refundStatus === 0) && (item.order.orderStatus == 5 || item.order.orderStatus == 7)) || item.orderDetails[0].orderRefundStatus == 6 || item.orderDetails[0].orderRefundStatus == 8">再次预订</div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>

      </div>
      <!--无订单数据-->
      <div v-else>
        <div class="d_f ali_c j_c">
          <div>
            <div class="no_order_out t_a">
              <img src="../img/no_order.png" alt="">
              <div class="title">你还没有订单哦！</div>
              <div class="sub">“我没有离开家，只是把家带回了远方~”</div>
              <div class="home_btn cur_p t_a d_i" @click="$router.push('/hotels')">回家去看看吧~</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--订单详情弹窗-->
    <payDetail v-if="isPayDetail" @close="isPayDetail = false"></payDetail>

    <!--退款原因-->
    <refundReason v-if="isShowRR" @close="refundSuccess" :orderNumber="rOrderNumber" :hotelPhone="hotelPhone"></refundReason>

    <!--评价-->
    <hotelComment v-if="isShowComment" @close="isShowComment = false" @save="reloadList" :orderId="commentOrderId"></hotelComment>
  </div>
</template>

<script>
  import apis from '../utils/apis'
  import payDetail from '../component/payDetail'
  import refundReason from "../../hotel/component/refundReason";
  import hotelComment from "../../hotel/component/hotelComment";
  import { Dialog,Toast, Image, CountDown, DropdownMenu, DropdownItem,PullRefresh, List, Overlay, Field, Icon, Rate } from 'vant';

  export default {
    name: "orderList",
      components:{
      payDetail,
      refundReason,
      hotelComment,
      'van-dropdown-menu': DropdownMenu,
      'van-dropdown-item': DropdownItem,
      'van-pull-refresh':PullRefresh,
      'van-list': List,
      'van-overlay': Overlay,
      'van-icon': Icon,
      'van-rate': Rate,
      'van-field': Field,
      'van-image': Image,
      'van-count-down': CountDown,
    },
    data() {
      return {
        navArr:[
          {name:'全部订单',state:-1},
          {name:'待付款',state:1},//0和1
          {name:'待使用',state:2},
          {name:'待评价',state:3},
          {name:'退款',state:4}],
        orderArr:[],
        acNav:sessionStorage.getItem('hotelListTab') ? Number(sessionStorage.getItem('hotelListTab')) : 0,
        isLoading:false,
        isShowRR:false,
        finished:false,
        isShowComment: false, //是否评价
        refreshing: false, //是否刷新
        isPayDetail: false, //详情弹窗
        params: { //门票搜索条件
          token: this.$global.token,
          orderType: '3',
          // orderNumber: "stls061120354509503",
          pageIndex: 1, //当前页码
          pageSize: 10, //每页显示记录数
          orderStatus: '-1', //订单状态
          sortOrder: "desc", //每页显示记录数
        },
        rOrderNumber:'',
        hotelPhone:'',
        commentOrderId:'',
      }
    },
    created(){
      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
      if(this.$global.token){
        this.params.token = this.$global.token;
        this.getList()
      }
    },
    methods: {
      goComment(row){
        this.commentOrderId = row.order.orderId;
        this.isShowComment = true;
      },
      refundSuccess(){
        let _t = this;
        _t.isShowRR = false;
        this.reloadList();
      },
      //切换tab
      changeState(row,index){
        let _t = this;
        _t.acNav = index;
        sessionStorage.setItem('hotelListTab',index);
        _t.finished = false;
        this.reloadList();
      },
      reloadList(){
        let _t = this;
        _t.isShowComment = false;
        _t.params.pageIndex = 1;
        _t.orderArr = [];
        _t.getList()
      },
      // 去支付
      gotoPay(row){
        this.$router.push({
          path:'/hotelPay',
          query:{
            price:row.orderDetails[0].sellPrice,
            buyNum:row.orderDetails.length,
            hotelOrderNum:row.order.orderNumber,
          }
        });
      },
      //撤回申请
      recallOrder(row){
        let _t = this;
        Dialog.confirm({
          cancelButtonText: '我再想想',
          message: '确定撤销申请吗？',
        })
          .then(() => {
            _t.$post({
              url: apis.recallHotelUserRefundApply,
              params:{
                token:_t.$global.token,
                orderNumber: row.order.orderNumber,
              }
            }).then(res => {
              Toast('操作成功');
              _t.reloadList();
            }).catch(err =>{
              Toast(err.message);
            })
          })
          .catch(() => {
            // on cancel
          });
      },
      //删除订单
      deleteOrder(row){
        let _t = this;
        Dialog.confirm({
          cancelButtonText: '我再想想',
          message: '确定删除订单吗？',
        })
          .then(() => {
            _t.$post({
              url: apis.deleteOrder,
              params:{
                token:_t.$global.token,
                orderId: row.order.orderId,
              }
            }).then(res => {
              Toast('操作成功');
              _t.reloadList();
            })
          })
          .catch(() => {
            // on cancel
          });

      },
      getList(){
        let _t = this;
        _t.isLoading = true;
        _t.params.orderStatus = _t.navArr[_t.acNav].state;
        _t.$get({
          url: apis.orderListHotel,
          params: _t.params
        }).then(res => {
          this.isLoading = false;
          if (_t.refreshing) {
            _t.refreshing = false;
          }
          if (res.count) {
            _t.orderArr = _t.orderArr.concat(res.data);
            _t.orderArr.forEach((ele, index) => {
              _t.orderArr[index] = Object.assign({}, ele, { color: null })
            });
          }
          let list = res.data;
          if (!list || list.length < _t.params.pageSize) {
            _t.finished = true;
          }
        })
      },
      //查看订单详情
      goToDetail(num){
        this.$router.push({
          path:'/hotelOrderDetail',
          query:{
            orderNumber:num
          }
        })
      },
      //取消订单
      cancelOrder(row){
        let me = this;
        Dialog.confirm({
          title: '提示',
          message: '确认取消订单？',
        }).then(() => {
          // on confirm
          me.$get({
            url:apis.cancelOrder,
            params: {
              orderId: row.order.orderId,
              token:me.$global.token,
            }
          }).then(res => {
            Toast('取消成功');
            me.reloadList();
          });
        })
          .catch(() => {
            // on cancel
          });

      },
      // 申请退款
      refundPay(row){
        Dialog.confirm({
          cancelButtonText: '我再想想',
          message: '确定申请退款吗？',
        })
          .then(() => {
            this.isShowRR = true;
            this.rOrderNumber = row.order.orderNumber;
            this.hotelPhone = row.orderDetails[0].telephone;
          })
          .catch(() => {
          });
      },
      //跳转酒店详情
      hotelDetail(row){
        this.$router.push({
          path:'/hotelDetail',
          query:{
            sellerId: row.orderDetails[0].sellerId,
          }
        })
      },
      //上啦加载
      onLoadBottom(){
        let me = this;
        let page = me.params.pageIndex;
        page = page ? page + 1 : 1;
        me.params.pageIndex = page;
        me.getList();
      },
      //下拉刷新
      onRefresh(){
        let me = this;
        me.orderArr = [];
        me.finished = false;
        me.params.pageIndex = 1;
        me.getList();
      },
    }
  }
</script>

<style scoped lang="scss">
  .ol_card_out{
    >.d_f{
      height: 100%;
    }
    .no_order_out{
      .home_btn{
        font-size:14px;
        font-weight:bold;
        color:rgba(255,255,255,1);
        width:167px;
        height:43px;
        line-height: 43px;
        background:linear-gradient(75deg,rgba(127,220,79,1),rgba(29,223,80,1));
        border-radius:22px;
      }
      .sub{
        font-size:12px;
        font-weight:bold;
        color:rgba(160,160,160,1);
        margin-bottom: 28px;
      }
      .title{
        font-size:14px;
        font-weight:bold;
        color:rgba(110,110,110,1);
        margin-bottom: 9px;
      }
      img{
        width: 230px;
        margin-bottom: 20px;
      }
    }
    .olc_item_out{
      .olc_btn_out{
        margin-top: 23px;
        .olc_btn{
          &.refund_btn{
            color: #b1b1b1;
            border: 1px solid #b1b1b1;
          }
          &.ac{
            color: #FFFFFF;
            background:linear-gradient(75deg,rgba(127,220,79,1),rgba(29,223,80,1));
            border-radius:13px;
            border: none;
          }
          a{
            color:rgba(62,62,62,1);
            text-decoration: none;
          }
          box-sizing: border-box;
          font-size:12px;
          font-weight:500;
          color:rgba(62,62,62,1);
          width:83px;
          height:26px;
          line-height: 24px;
          border:1px solid rgba(160,160,160,1);
          border-radius:13px;
          margin-left: 18px;
        }
      }
      .van-image{
        width: 111px;
        height: 109px;
      }
      .olcc_right{
        .price{
          span:nth-child(3){
            color: #3E3E3E;
            font-size: 17px;
          }
          span:nth-child(2){
            margin-left: 12px;
            color: #3E3E3E;
          }
          font-size:12px;
          font-weight:500;
          color:rgba(62,62,62,1);
          margin-top: 9px;
        }
        .sub{
          font-size:12px;
          font-weight:500;
          color:rgba(62,62,62,1);
          margin-top: 7px;
        }
        .name{
          font-size:14px;
          font-weight:bold;
          color:rgba(62,62,62,1);
        }
        margin-left: 12px;
      }
      .head{
        .flex_1{
          color: #A0A0A0;
          &.green{
            color: #3AD78F;
            .van-count-down{
              font-size:13px;
              color: #3AD78F;
            }
          }
        }
        .icon{
          width: 15px;
          margin-right: 8px;
        }
        margin-bottom: 20px;
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
      }
      border-radius:3px;
      padding: 12px 10px;
      background-color: white;
      margin-bottom: 13px;
    }
    /*.van-pull-refresh{
      height: 100%;
    }*/
    height: calc(100% - 70px);
    width: 100%;
    overflow: auto;
  }
  .ol_tab{
    .ol_tab_item{
      &.ac{
        color: rgba(29,223,80,1);
        span{
          &::after{
            content: ' ';
            display: block;
            position: absolute;
            width: 100%;
            height:3px;
            background:linear-gradient(75deg,rgba(127,220,79,1),rgba(29,223,80,1));
            border-radius:1px;
            bottom: -6px;
          }
        }
      }
      &:last-child{
        padding-right: 0;
        border-right: none;

      }
      padding-right: 20px;
      text-align: center;
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
      border-right: 1px solid #A0A0A0;
    }
    padding: 15px 0 19px 0;
  }
  .ol_out{
    &.bg_white{
      background-color: white;
    }
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 21px;
    background-color: #F0F0F0;
  }
  .jfje{
    text-align: right;
    font-size: 14px;
    line-height: 30px;
    margin: 10px 0 -10px 0;
    span{
      margin-left: 20px;
    }
  }
</style>
